@import '../../../general/scss/settings/variables.scss';

.header {
    background-color: $white;
}

.header-container {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.map-body {
    .header-container {
        padding-bottom: .5rem;
        padding-top: .5rem;
    }

    .header-nav-container {
        line-height: 1;
    }
}

@include media-breakpoint-down(md) {
    .header-nav-container {
        background-color: white;
        position: absolute;
        z-index: 500;
        width: 50%;
    }
}

@include media-breakpoint-down(sm) {
    .header-nav-container {
        width: 100%;
    }
}

.search-input {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.search-container-sm {
    background-color: $white;
    padding: 0.75rem;

    .search-input {
        width: 85%;
    }

    @include media-breakpoint-up(md) {
        .search-input {
            width: 90%;
        }
    }

    /*.btn-search {
		width: 15%;
	}*/
}